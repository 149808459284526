.footerSection {
    color: white;
    background: #262626;
}
.footerRow {
    margin: 24px;
    padding: 24px;
}
.twitter {
    font-variant: all-small-caps;
}
.twitter a.nav-link {
    padding: 0px;
}
.linkedin {
    font-variant: all-small-caps;
}
.linkedin a.nav-link {
    padding: 0px;
}
.github {
    font-variant: all-small-caps;
}
.github a.nav-link {
    padding: 0px;
}