.jumbotron {
     /* opacity: 0.6; */
     /* background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(../images/Nice-Sunset-Trimmed3.png); */
     /* background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(../images/computer2.png); */
     /* background-size: cover; */
     text-align: center;
     color:black;
     background-color: white;
     margin: 12px;
     padding: 24px;
}
.jumbotron h1 {
     font-weight: 600;
}
