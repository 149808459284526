.navbar-light .navbar-brand {
    color: #00abff !important;
    font-variant-caps: all-small-caps;
    font-size: 28px;
}
/* .navbar-brand:hover {
    color: #0095e6 !important;
    font-weight: 600;
} */
.navbar-brand img {
    /* filter: invert(41%) sepia(99%) saturate(2498%) hue-rotate(173deg) brightness(90%) contrast(101%); */
    filter: invert(98%) sepia(49%) saturate(2708%) hue-rotate(177deg) brightness(95%) contrast(115%);
    border-radius: 50%;
}
.navbar-light .navbar-nav .nav-link{
    color: #00abff !important;
    font-size: 20px;
}
.nav-link:hover {
    /* color: #00abff !important; */
    text-decoration: underline;
}
.nav-link.active {
    color: #00abff !important;
    text-decoration: underline;
}
.nav-item {
    margin-left: 12px !important;
}