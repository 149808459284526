.featuredProjectsContainer {
    margin-top: 12px;
    margin-bottom: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
}
.headerRow {
    justify-content: center;
    margin: 12px;
}
.headerRow h1 {
    font-weight: 600;
}
.projectRow {
    margin-top: 24px;
}
.spacerRow {
    margin: 24px;
}